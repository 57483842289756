export const fakeData = {
  data: [
    {
      _id: '66c5ddbabb1f8b01f2dadfbd',
      player_rank: 0,
      player_bio: '',
      user_id: '0x8f025cD01C09AfffB12B4aD2aBc7C7cBa40088Ad',
      nameGuild: 'Ahihi',
      address_wallet: '0x8f025cD01C09AfffB12B4aD2aBc7C7cBa40088Ad',
      use_yn: 'Y',
      level: 10,
      rank: 10,
      rankMax: 15,
      bit_balance: 50000,
      del_if: 0,
      ipaddress: '46.166.60.15',
      last_active: '2024-08-21T12:29:46.373Z',
      last_login: '2024-08-21T12:39:20.341Z',
      timezone_offset: 0,
      total_login_day: 1,
      login_day: 1,
      items: [
        {
          collection: 4,
          typeItem: ['REGENERATION_HASH_RANDOM_R'],
          amount: [5],
        },
        {
          collection: 2,
          typeItem: ['STR_SEED_UC'],
          amount: [5],
        },
      ],
      members: 10,
      membersMax: 15,
      master: 'Đình Luân',
      score: [
        {
          total: 15632,
          Cycle: 3512,
          Month: 135,
        },
      ],
      playerGameProgress: {
        _id: '66c5ddbabb1f8b01f2dadfbe',
        player_rank: 0,
        highest_monster_rank: 0,
      },
    },
    {
      _id: '66c5ddbabb1f8b01f2dadfbd',
      player_rank: 0,
      player_bio: '',
      user_id: '0x8f025cD01C09AfffB12B4aD2aBc7C7cBa40088Ad',
      nameGuild: 'Bang 1303',
      address_wallet: '0x8f025cD01C09AfffB12B4aD2aBc7C7cBa40088Ad',
      use_yn: 'Y',
      level: 10,
      rank: 10,
      rankMax: 15,
      bit_balance: 50000,
      del_if: 0,
      ipaddress: '46.166.60.15',
      last_active: '2024-08-21T12:29:46.373Z',
      last_login: '2024-08-21T12:39:20.341Z',
      timezone_offset: 0,
      total_login_day: 1,
      login_day: 1,
      items: [
        {
          collection: 4,
          typeItem: ['REGENERATION_HASH_RANDOM_R'],
          amount: [5],
        },
        {
          collection: 2,
          typeItem: ['STR_SEED_UC'],
          amount: [5],
        },
      ],
      members: 10,
      membersMax: 15,
      master: 'Đình Luân',
      score: [
        {
          total: 15632,
          Cycle: 3512,
          Month: 135,
        },
      ],
      playerGameProgress: {
        _id: '66c5ddbabb1f8b01f2dadfbe',
        player_rank: 0,
        highest_monster_rank: 0,
      },
    },
  ],
  total: 2,
  hasPreviousPage: false,
  hasNextPage: true,
  pageSize: 10,
  currentPage: 1,
}
