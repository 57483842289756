export const ROUTE = {
  HOME: {
    PATH: '/marketplace-dashboard',
    TITLE: 'dashboard',
  },
  MARKETPLACE_DASHBOARD: {
    PATH: '/marketplace-dashboard',
    TITLE: 'marketplaceDashboard',
  },
  SHOP_DASHBOARD: {
    PATH: '/shop-dashboard',
    TITLE: 'shopDashboard',
  },
  GAME_DASHBOARD: {
    PATH: '/game-dashboard',
    TITLE: 'gameDashboard',
  },
  HISTORY_TRADE: {
    PATH: '/history-trade',
    TITLE: 'historyTrade',
  },
  MANAGEMENT_COLLABORATION_NFT: {
    PATH: '/management-collaboration-nft',
    TITLE: 'managementCollaborationNFT',
  },
  MARKETPLACE_SETTINGS: {
    PATH: '/marketplace-settings',
    TITLE: 'marketplaceSettings',
  },
  SHOP: {
    PATH: '/shop',
    TITLE: 'shopSettings',
  },
  MANAGEMENT_POOLS: {
    PATH: '/management-pools',
    TITLE: 'managementPools',
  },
  HASHCHIP_REDEEMED: {
    PATH: '/hashchip-redeemed',
    TITLE: 'hashchipRedeemed',
  },
  RANKING_REWARDS: {
    PATH: '/ranking-rewards',
    TITLE: 'rankingRewards',
  },
  MANAGEMENT_USERS: {
    PATH: '/management-users',
    TITLE: 'managementUsers',
  },
  ADMINS: {
    PATH: '/admins',
    TITLE: 'admins',
  },
  USERS: {
    PATH: '/users',
    TITLE: 'users',
  },
  GUILD: {
    PATH: '/guild',
    TITLE: 'guild',
  },
  PROFILE_SETTINGS: {
    PATH: '/profile-settings',
    TITLE: 'profileSettings',
  },
  MANAGEMENT_GIFT: {
    PATH: '/management-gift',
    TITLE: 'managementGift',
  },
}
