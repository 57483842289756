import PropTypes from 'prop-types'
// import { useTranslation } from '~/common/hooks/useTranslation'
import { Form, Modal, Row, Col, Progress } from 'antd'
import React from 'react'
import { useTranslation } from '~/common/hooks/useTranslation'

function GuildDetail(props) {
  const { t } = useTranslation()
  const { isShowModal, onClose, id } = props

  const [form] = Form.useForm()

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  return (
    <Modal
      title={<>{t('managementGuilds.form.guildDetail')}</>}
      onCancel={handleClose}
      open={isShowModal}
      width={1000}
      centered
      destroyOnClose
      footer={null}
    >
      <div>
        {/* GuildName */}
        <Row gutter={24} align="middle" style={{ fontFamily: 'Rexliafree' }}>
          <Col span={8} className="flex items-center">
            <img
              className="w-[60px] h-[60px] mr-2"
              src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon.svg`}
              alt=""
            />
            <div className="text-[24px]"> Guild Name</div>
          </Col>
          <Col span={16} className="flex items-center">
            <Row style={{ width: '100%' }} align="middle">
              <Col span={3} style={{ textAlign: 'center', fontSize: '20px' }}>
                Lv.10
              </Col>
              <Col span={16} style={{ paddingRight: '30px' }}>
                <Progress
                  percent={(100 / 2000) * 100}
                  format={() => `${100}/${2000}`}
                />
              </Col>

              <Col
                span={5}
                style={{
                  display: 'flex',
                  border: '1px solid #66BB6A',
                  justifyContent: 'center',
                  background: '#66BB6A',
                  borderRadius: '10px',
                }}
              >
                <img
                  className="w-[30px] h-[30px] mr-2"
                  src={`${process.env.PUBLIC_URL}/svgs/guild/groupMember.svg`}
                  alt=""
                />
                <div className="text-[20px]">8/10</div>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* Guild Rank */}
        <Row
          gutter={24}
          align="middle"
          className="mt-[15px]"
          style={{ fontFamily: 'Rexliafree' }}
        >
          <Col span={24} className="flex items-center justify-center">
            <img
              className="w-[30px] h-[30px] mr-2"
              src={`${process.env.PUBLIC_URL}/svgs/guild/rank.svg`}
              alt=""
            />
            <div className="text-[20px] "> Guild Rank</div>
          </Col>
        </Row>
        <Row
          className="mt-[15px] p-[12px]"
          justify="space-between"
          style={{ fontFamily: 'Rexliafree' }}
        >
          <Col
            span={7}
            style={{
              border: '1px solid #E5E5E5',
              borderRadius: 8,
              background: '#004558',
              padding: '0',
            }}
          >
            <div>
              <Row style={{ padding: '0 12px' }}>
                <Col span={12} className="text-[20px]">
                  Jan. 2024
                </Col>
                <Col
                  span={12}
                  className="flex items-center text-[20px] justify-end"
                >
                  <img
                    className="w-[35px] h-[35px]"
                    src={`${process.env.PUBLIC_URL}/svgs/guild/oas.svg`}
                    alt=""
                  />
                  <div>10,000</div>
                </Col>
              </Row>
              <div
                style={{
                  background: '#0D2758',
                  padding: '12px',
                  borderBottomLeftRadius: 8,
                  borderBottomRightRadius: 8,
                }}
              >
                <Row>
                  <Col span={13} className="text-[16px]">
                    Rank
                  </Col>
                  <Col span={11} className="text-[16px] flex justify-end">
                    16/123
                  </Col>
                </Row>
                <Row>
                  <Col span={13} className="text-[16px]">
                    Total Score
                  </Col>
                  <Col span={11} className="text-[16px] flex justify-end">
                    200
                  </Col>
                </Row>
                <Row>
                  <Col span={13} className="flex items-center">
                    <img
                      className="w-[12px] h-[12px] mr-1"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                      alt=""
                    />
                    <div className="text-[12px]">Ranked Match</div>
                  </Col>
                  <Col
                    span={11}
                    className="flex items-center justify-end text-[12px]"
                  >
                    123
                  </Col>
                </Row>
                <Row>
                  <Col span={13} className="flex items-center">
                    <img
                      className="w-[12px] h-[12px] mr-1"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                      alt=""
                    />
                    <div className="text-[12px]">Exploration</div>
                  </Col>
                  <Col
                    span={11}
                    className="flex items-center justify-end text-[12px]"
                  >
                    95
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col
            span={7}
            style={{
              border: '1px solid #E5E5E5',
              borderRadius: 8,
              background: '#004558',
              padding: '0',
            }}
          >
            <div>
              <Row style={{ padding: '0 12px' }}>
                <Col span={12} className="text-[20px]">
                  Feb. 2024
                </Col>
                <Col
                  span={12}
                  className="flex items-center text-[20px] justify-end"
                >
                  <img
                    className="w-[35px] h-[35px]"
                    src={`${process.env.PUBLIC_URL}/svgs/guild/oas.svg`}
                    alt=""
                  />
                  <div>10,000</div>
                </Col>
              </Row>
              <div
                style={{
                  background: '#0D2758',
                  padding: '12px',
                  borderBottomLeftRadius: 8,
                  borderBottomRightRadius: 8,
                }}
              >
                <Row>
                  <Col span={13} className="text-[16px]">
                    Rank
                  </Col>
                  <Col span={11} className="text-[16px] flex justify-end">
                    16/123
                  </Col>
                </Row>
                <Row>
                  <Col span={13} className="text-[16px]">
                    Total Score
                  </Col>
                  <Col span={11} className="text-[16px] flex justify-end">
                    200
                  </Col>
                </Row>
                <Row>
                  <Col span={13} className="flex items-center">
                    <img
                      className="w-[12px] h-[12px] mr-1"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                      alt=""
                    />
                    <div className="text-[12px]">Ranked Match</div>
                  </Col>
                  <Col
                    span={11}
                    className="flex items-center justify-end text-[12px]"
                  >
                    123
                  </Col>
                </Row>
                <Row>
                  <Col span={13} className="flex items-center">
                    <img
                      className="w-[12px] h-[12px] mr-1"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                      alt=""
                    />
                    <div className="text-[12px]">Exploration</div>
                  </Col>
                  <Col
                    span={11}
                    className="flex items-center justify-end text-[12px]"
                  >
                    95
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col
            span={7}
            style={{
              border: '1px solid #E5E5E5',
              borderRadius: 8,
              background: '#0CC8FE',
              padding: '0',
            }}
          >
            <div>
              <Row style={{ padding: '0 12px' }}>
                <Col span={12} className="text-[20px] pb-1">
                  Mar. 2024
                </Col>
              </Row>
              <div
                style={{
                  background: '#0D2758',
                  padding: '12px',
                  borderBottomLeftRadius: 8,
                  borderBottomRightRadius: 8,
                }}
              >
                <Row>
                  <Col span={13} className="text-[16px]">
                    Rank
                  </Col>
                  <Col span={11} className="text-[16px] flex justify-end">
                    16/123
                  </Col>
                </Row>
                <Row>
                  <Col span={13} className="text-[16px]">
                    Total Score
                  </Col>
                  <Col span={11} className="text-[16px] flex justify-end">
                    200
                  </Col>
                </Row>
                <Row>
                  <Col span={13} className="flex items-center">
                    <img
                      className="w-[12px] h-[12px] mr-1"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                      alt=""
                    />
                    <div className="text-[12px]">Ranked Match</div>
                  </Col>
                  <Col
                    span={11}
                    className="flex items-center justify-end text-[12px]"
                  >
                    123
                  </Col>
                </Row>
                <Row>
                  <Col span={13} className="flex items-center">
                    <img
                      className="w-[12px] h-[12px] mr-1"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                      alt=""
                    />
                    <div className="text-[12px]">Exploration</div>
                  </Col>
                  <Col
                    span={11}
                    className="flex items-center justify-end text-[12px]"
                  >
                    95
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        {/* Cycle */}
        <Row
          gutter={24}
          align="middle"
          className="mt-[15px]"
          style={{ fontFamily: 'Rexliafree' }}
        >
          <Col span={24} className="flex items-center justify-center">
            <img
              className="w-[20px] h-[20px] mr-2 cursor-pointer"
              src={`${process.env.PUBLIC_URL}/svgs/guild/returnLeft.svg`}
              alt=""
            />
            <div className="text-[20px] ">Cycle 2</div>
            <div className="text-[12px] ml-2">(Jan. 2024 - Mar. 2024)</div>
          </Col>
        </Row>

        <Row gutter={24} className="mt-[12px] p-[12px]">
          <Col span={12} style={{ fontFamily: 'Rexliafree' }}>
            <Row className="mb-[12px]">
              <Col span={12} className="flex items-center">
                <img
                  className="w-[20px] h-[20px] mr-1"
                  src={`${process.env.PUBLIC_URL}/svgs/guild/guildMaster.svg`}
                  alt=""
                />
                <div className="text-[16px]">Guild Master</div>
              </Col>
              <Col span={12} className="text-[16px] flex justify-center">
                Player Name
              </Col>
            </Row>
            <Row className="mb-[12px]">
              <Col span={12} className="flex items-center">
                <img
                  className="w-[20px] h-[20px] mr-1"
                  src={`${process.env.PUBLIC_URL}/svgs/guild/subGuild.svg`}
                  alt=""
                />
                <div className="text-[16px]">Sub Master1</div>
              </Col>
              <Col span={12} className="text-[16px] flex justify-center">
                Player Name
              </Col>
            </Row>
            <Row className="mb-[12px]">
              <Col span={12} className="flex items-center ">
                <img
                  className="w-[20px] h-[20px] mr-1"
                  src={`${process.env.PUBLIC_URL}/svgs/guild/subGuild.svg`}
                  alt=""
                />
                <div className="text-[16px]">Sub Master2</div>
              </Col>
              <Col span={12} className="text-[12px] flex justify-center">
                <div
                  className="flex items-center justify-center"
                  style={{
                    border: '1px solid #fff',
                    borderRadius: '4px',
                    padding: '4px',
                    width: '52%',
                  }}
                >
                  <img
                    className="w-[15px] h-[15px] mr-1"
                    src={`${process.env.PUBLIC_URL}/svgs/guild/keyGuild.svg`}
                    alt=""
                  />
                  <div>Guild level 6</div>
                </div>
              </Col>
            </Row>
            <Row className="mb-[12px]">
              <Col span={12} className="flex items-center">
                <img
                  className="w-[20px] h-[20px] mr-1"
                  src={`${process.env.PUBLIC_URL}/svgs/guild/subGuild.svg`}
                  alt=""
                />
                <div className="text-[16px]">Sub Master3</div>
              </Col>
              <Col span={12} className="text-[12px] flex justify-center">
                <div
                  className="flex items-center justify-center"
                  style={{
                    border: '1px solid #fff',
                    borderRadius: '4px',
                    padding: '4px',
                    width: '52%',
                  }}
                >
                  <img
                    className="w-[15px] h-[15px] mr-1"
                    src={`${process.env.PUBLIC_URL}/svgs/guild/keyGuild.svg`}
                    alt=""
                  />
                  <div>Guild level 11</div>
                </div>
              </Col>
            </Row>
            <Row className="mb-[12px]">
              <Col span={12} className="flex items-center">
                <div className="text-[16px] pl-[10%]"> Earned OAS</div>
              </Col>
              <Col span={12} className=" flex text-[16px]  justify-center">
                <img
                  className="w-[25px] h-[25px] mr-1"
                  src={`${process.env.PUBLIC_URL}/svgs/guild/oas.svg`}
                  alt=""
                />
                <div>20,000</div>
              </Col>
            </Row>
            <Row className="mb-[12px]">
              <Col span={12} className="flex items-center ">
                <div className="text-[16px] pl-[10%]">Establishment</div>
              </Col>
              <Col span={12} className="text-[16px] flex justify-center">
                Jan. 1. 2024
              </Col>
            </Row>
          </Col>
          <Col
            span={12}
            className="guildInfo"
            style={{
              border: '1px solid #fff',
              borderRadius: '8px',
              padding: '12px',
              maxHeight: '230px',
              overflowY: 'auto',
            }}
          >
            <div
              className="text-[20px] flex justify-center items-center mb-3 "
              style={{ fontFamily: 'Rexliafree' }}
            >
              Noticeboard
            </div>
            <div style={{ fontWeight: '500' }}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

GuildDetail.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default GuildDetail
