import { ROUTE } from './config'

import HistoryTrade from '~/views/app/features/HistoryTrade'
import ManagementCollaborationNFT from '~/views/app/features/ManagementCollaborationNFT'
import { Shop } from '~/views/app/features/Shop'
import MarketplaceSettings from '~/views/app/features/MarketplaceSettings'
import ManagementAdmins from '~/views/app/features/ManagementUsers/Admins'
import ManagementUsers from '~/views/app/features/ManagementUsers/Users'
import ManagementGuilds from '~/views/app/features/Guild'
import ProfileSettings from '~/views/app/features/ProfileSettings'
import MarketplaceDashboard from '~/views/app/features/Dashboard/Marketplace'
import ShopDashboard from '~/views/app/features/Dashboard/Shop'
import GameDashboard from '~/views/app/features/Dashboard/Game'
import ManagementGift from '~/views/app/features/ManagementGift'
import RankingRewards from '~/views/app/features/ManagementPools/RankingRewards'
import HashchipRedeemed from '~/views/app/features/ManagementPools/HashchipRedeemed'

const routes = [
  {
    name: ROUTE.MARKETPLACE_DASHBOARD.TITLE,
    path: ROUTE.MARKETPLACE_DASHBOARD.PATH,
    component: MarketplaceDashboard,
  },
  {
    name: ROUTE.SHOP_DASHBOARD.TITLE,
    path: ROUTE.SHOP_DASHBOARD.PATH,
    component: ShopDashboard,
  },
  {
    name: ROUTE.GAME_DASHBOARD.TITLE,
    path: ROUTE.GAME_DASHBOARD.PATH,
    component: GameDashboard,
  },
  {
    name: ROUTE.HISTORY_TRADE.TITLE,
    path: ROUTE.HISTORY_TRADE.PATH,
    component: HistoryTrade,
  },
  {
    name: ROUTE.MANAGEMENT_COLLABORATION_NFT.TITLE,
    path: ROUTE.MANAGEMENT_COLLABORATION_NFT.PATH,
    component: ManagementCollaborationNFT,
  },
  {
    name: ROUTE.MARKETPLACE_SETTINGS.TITLE,
    path: ROUTE.MARKETPLACE_SETTINGS.PATH,
    component: MarketplaceSettings,
  },
  {
    name: ROUTE.SHOP.TITLE,
    path: ROUTE.SHOP.PATH,
    component: Shop,
  },
  {
    name: ROUTE.HASHCHIP_REDEEMED.TITLE,
    path: ROUTE.HASHCHIP_REDEEMED.PATH,
    component: HashchipRedeemed,
  },
  {
    name: ROUTE.RANKING_REWARDS.TITLE,
    path: ROUTE.RANKING_REWARDS.PATH,
    component: RankingRewards,
  },
  {
    name: ROUTE.ADMINS.TITLE,
    path: ROUTE.ADMINS.PATH,
    component: ManagementAdmins,
  },
  {
    name: ROUTE.USERS.TITLE,
    path: ROUTE.USERS.PATH,
    component: ManagementUsers,
  },
  {
    name: ROUTE.GUILD.TITLE,
    path: ROUTE.GUILD.PATH,
    component: ManagementGuilds,
  },
  {
    name: ROUTE.PROFILE_SETTINGS.TITLE,
    path: ROUTE.PROFILE_SETTINGS.PATH,
    component: ProfileSettings,
  },
  {
    name: ROUTE.MANAGEMENT_GIFT.TITLE,
    path: ROUTE.MANAGEMENT_GIFT.PATH,
    component: ManagementGift,
  },
]

export default routes
